import { MutableRefObject, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { EVENT_NAMES, sendEvent } from '../analytics';
import { captureException } from '../tracking/sentry/sentry';
import type { HlsConfig } from 'hls.js';
import type Hls from 'hls.js';

export { HlsConfig };
/**
 * IMPORTANT NOTE:
 * We have our own code in the backend to generate the streaming video.
 * The resolution is predefined and will not crop the video if the original has a different resolution.
 * This will result in the streaming video having black padding horizontally or vertically
 */
const useStreamingVideo = ({
  src,
  ref,
  hlsConfig,
}: {
  src?: string;
  ref: MutableRefObject<HTMLVideoElement | undefined> | RefObject<HTMLVideoElement>;
  hlsConfig?: Partial<HlsConfig>;
}) => {
  const hlsRef = useRef<Hls>();
  const [doneLoading, setDoneLoading] = useState(false);
  const playPromise = useRef<Promise<void>>();

  useEffect(() => {
    if (!src || !ref?.current) {
      setDoneLoading(true);
      return;
    }

    if (!ref.current?.canPlayType('application/x-mpegURL')) {
      import('hls.js').then(({ default: Hls, Events }) => {
        const hls = new Hls({
          capLevelToPlayerSize: true,
          workerPath: '/hls.worker.js',
          ...hlsConfig,
        });

        hlsRef.current = hls;
        ref.current && hls.attachMedia(ref.current);

        hls.on(Events.MEDIA_ATTACHED, function () {
          hls.loadSource(src);
          hls.on(Events.MANIFEST_PARSED, function (_, data) {
            hls.startLevel = data.levels.length;
            hls.startLoad();
            setDoneLoading(true);
          });
        });
      });
      return () => hlsRef.current && hlsRef.current.destroy();
    } else {
      setDoneLoading(true);
    }
  }, [src, ref, hlsConfig]);

  const pause = useCallback(async () => {
    try {
      if (playPromise.current !== undefined) {
        await playPromise.current;
        ref.current?.pause();
      } else {
        ref.current?.pause();
      }
    } catch (error) {
      // we want analytics data not bug report
      sendEvent({
        name: EVENT_NAMES.videoPauseError,
        properties: {
          error,
          src,
        },
      });
    }
  }, [ref, src]);

  const play = useCallback(async () => {
    if (!ref.current) {
      captureException(new Error('Trying to play video but ref is not defined'));
      return;
    }
    try {
      playPromise.current = ref.current.play();
      await playPromise.current;
    } catch (error) {
      // we want analytics data not bug report
      sendEvent({
        name: EVENT_NAMES.videoPlayError,
        properties: {
          error,
          src,
        },
      });
    }
  }, [ref, src]);

  return {
    doneLoading,
    play,
    pause,
  };
};

export default useStreamingVideo;
