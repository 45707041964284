import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDeviceVideoUrls } from '../../../utils/hooks/useDeviceVideo';
import { breakpoints, colors, spacing } from '../../../utils/styleguide';
import AspectRatioBox from '../layout/AspectRatioBox';
import Typography from '../text/Typography';
import VideoPlayer, { type HlsConfig } from '../video/FullScreenVideoPlayer';

const Container = styled(AspectRatioBox)<{ isMaxWidth: boolean }>`
  ${breakpoints.tablet} {
    ${({ isMaxWidth }) =>
      isMaxWidth
        ? css`
            width: 100%;
          `
        : css`
            width: auto;
          `}
  }
`;

const StackingContext = styled.div<{ isResponsive: boolean }>`
  ${({ isResponsive }) =>
    isResponsive
      ? css`
          position: absolute;
        `
      : css`
          position: relative;
        `};

  z-index: 0;
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.3;
  background-color: black;
`;

const VideoCredit = styled.div`
  position: absolute;
  bottom: ${spacing[1]}px;
  left: ${spacing[4]}px;
  right: ${spacing[4]}px;
  text-align: right;
`;

const ContentWrapper = styled.div<{
  passThroughPointerEvents: boolean;
  isResponsive: boolean;
}>`
  ${({ isResponsive }) =>
    isResponsive
      ? css`
          position: relative;
        `
      : css`
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
        `}

  ${({ passThroughPointerEvents }) =>
    passThroughPointerEvents &&
    css`
      pointer-events: none;
    `}
`;

const defaultAspectRatios = {
  mobile: 4 / 5,
  tablet: 16 / 9,
  desktop: 16 / 9,
};

export type BackgroundVideoProps = {
  videoLargeScreenUrl?: string;
  videoSmallScreenUrl?: string;
  posterLargeScreenUrl?: string;
  posterSmallScreenUrl?: string;
  children?: ReactNode;
  hlsConfig?: Partial<HlsConfig>;
  aspectRatios?: Partial<typeof defaultAspectRatios>;
  hideMute?: boolean;
  passThroughPointerEvents?: boolean;
  showControls?: boolean;
  showDefaultControls?: boolean;
  hasOverlay?: boolean;
  isResponsive?: boolean;
  videoCredit?: string;
  isMaxWidth?: boolean;
};

const BackgroundVideo = ({
  videoLargeScreenUrl = '',
  videoSmallScreenUrl = '',
  posterLargeScreenUrl = '',
  posterSmallScreenUrl = '',
  children,
  hlsConfig,
  aspectRatios,
  hideMute = true,
  passThroughPointerEvents = false,
  showControls = true,
  showDefaultControls = false,
  hasOverlay,
  isResponsive = true,
  videoCredit,
  isMaxWidth = false,
}: BackgroundVideoProps) => {
  const { videoUrl: url, posterUrl } = useDeviceVideoUrls({
    largeScreen: {
      videoUrl: videoLargeScreenUrl,
      posterUrl: posterLargeScreenUrl,
    },
    smallScreen: {
      videoUrl: videoSmallScreenUrl,
      posterUrl: posterSmallScreenUrl,
    },
  });

  const { ref, inView } = useInView();

  return (
    <Container
      isMaxWidth={isMaxWidth}
      responsiveAspectRatios={{ ...defaultAspectRatios, ...aspectRatios }}
      boxRef={ref}
    >
      <StackingContext isResponsive={isResponsive}>
        <VideoPlayer
          src={url}
          shouldPlay={inView}
          hideMute={hideMute}
          hlsConfig={hlsConfig}
          posterSrc={posterUrl}
          showControls={showControls}
          showDefaultControls={showDefaultControls}
        />
      </StackingContext>
      {hasOverlay && <Overlay />}
      {children && (
        <ContentWrapper
          passThroughPointerEvents={passThroughPointerEvents}
          isResponsive={isResponsive}
        >
          {children}
        </ContentWrapper>
      )}
      {videoCredit && (
        <VideoCredit>
          <Typography color={colors.whitePrimary} variant="overlineSmall">
            {videoCredit}
          </Typography>
        </VideoCredit>
      )}
    </Container>
  );
};

export default BackgroundVideo;
