import styled from '@emotion/styled';
import Icon from '../Icon';

const PlayArrowIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
`;

/**
 *
 * @param {object} props
 * @param {function(*):void} props.onClick
 */
export const PlayButton = ({ onClick }: { onClick?: () => void }) => {
  return <PlayArrowIcon onClick={onClick} aria-label="Play" name={'play-video'} size={100} />;
};
