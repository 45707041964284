import { useEffect, useState } from 'react';
import { breakpointQueries } from '../styleguide';
import { useMediaQuery } from './useMediaQuery';

type VideoUrlProps = {
  largeScreen: {
    videoUrl?: string;
    posterUrl?: string;
  };
  smallScreen?: {
    videoUrl?: string;
    posterUrl?: string;
  };
};

export const useDeviceVideoUrls = ({ largeScreen, smallScreen }: VideoUrlProps) => {
  const [isTabletOrLarger, mediaQueryDetermined] = useMediaQuery(breakpointQueries.tablet);
  const [videoUrl, setVideoUrl] = useState(largeScreen.videoUrl || '');
  const [posterUrl, setPosterUrl] = useState(largeScreen.posterUrl);

  useEffect(() => {
    if (mediaQueryDetermined && isTabletOrLarger === false && smallScreen) {
      smallScreen.videoUrl && setVideoUrl(smallScreen.videoUrl);
      smallScreen.posterUrl && setPosterUrl(smallScreen.posterUrl);
    } else if (mediaQueryDetermined && isTabletOrLarger && largeScreen) {
      largeScreen.videoUrl && setVideoUrl(largeScreen.videoUrl);
      largeScreen.posterUrl && setPosterUrl(largeScreen.posterUrl);
    }
  }, [isTabletOrLarger, mediaQueryDetermined, smallScreen, largeScreen]);

  return { videoUrl, posterUrl };
};

type VideoProps<T> = {
  landscapeVideo?: T;
  portraitVideo?: T;
};

export function useDeviceVideo<T>({ landscapeVideo, portraitVideo }: VideoProps<T>): T | undefined {
  const [isTabletOrLarger, mediaQueryDetermined] = useMediaQuery(breakpointQueries.tablet);
  const [video, setVideo] = useState(portraitVideo || landscapeVideo);

  useEffect(() => {
    if (mediaQueryDetermined && isTabletOrLarger && landscapeVideo) {
      setVideo(landscapeVideo);
    }
    if (mediaQueryDetermined && !isTabletOrLarger && portraitVideo) {
      setVideo(portraitVideo);
    }
  }, [isTabletOrLarger, mediaQueryDetermined, portraitVideo, landscapeVideo]);

  return video;
}
